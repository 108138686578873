import { MarketSlug } from 'api/nest'

export const TOOLTIP_ID = 'svgTooltip'

export const MAP_VIEW_BOX_WIDTH = 835

interface MapGroupPinCoordinates {
  x: number
  y: number
  marketSlugs: MarketSlug[] | [MarketSlug]
}

export const MARKETS_COORDINATES: MapGroupPinCoordinates[] = [
  {
    marketSlugs: [MarketSlug.columbus],
    x: 620,
    y: 175,
  },
  {
    marketSlugs: [MarketSlug.lasVegas],
    x: 100,
    y: 242,
  },
  {
    marketSlugs: [MarketSlug.raleigh],
    x: 715,
    y: 232,
  },
  {
    marketSlugs: [MarketSlug.seattle],
    x: 50,
    y: 8,
  },
  {
    marketSlugs: [MarketSlug.phoenix],
    x: 150,
    y: 330,
  },
  {
    marketSlugs: [MarketSlug.tampa, MarketSlug.tampaStPetes],
    x: 680,
    y: 410,
  },
  {
    marketSlugs: [MarketSlug.miami],
    x: 710,
    y: 435,
  },
  {
    marketSlugs: [MarketSlug.charlotte],
    x: 695,
    y: 257,
  },
  {
    marketSlugs: [MarketSlug.stPetersburg],
    x: 670,
    y: 435,
  },
  {
    marketSlugs: [MarketSlug.newYork, MarketSlug.brooklyn, MarketSlug.manhattan, MarketSlug.queens],
    x: 751,
    y: 119,
  },
  {
    marketSlugs: [MarketSlug.washington],
    x: 727,
    y: 208,
  },
  {
    marketSlugs: [MarketSlug.denver],
    x: 275,
    y: 208,
  },
  {
    marketSlugs: [MarketSlug.dallas],
    x: 435,
    y: 355, // Dallas is toward the NE corner of Texas
  },
  {
    marketSlugs: [MarketSlug.austin],
    x: 400,
    y: 375, // Austin is below Dallas, roughly central
  },
  {
    marketSlugs: [MarketSlug.sanAntonio],
    x: 375,
    y: 425, // San Antonio is south-southwest of Austin
  },
  {
    marketSlugs: [MarketSlug.houston],
    x: 440,
    y: 400, // Houston is southeast of Austin
  },
]
